import Vue from "vue";
import Vuex from "vuex";

import auth from "./modules/auth";
import references from "./modules/references";
import roles from "./modules/roles";
import users from "./modules/users";
import customFields from "./modules/customFields";
import vouchers from "./modules/vouchers";
import properties from "./modules/properties";
import categories from "./modules/categories";
import pointAdjustment from "./modules/pointAdjustment";
import members from "./modules/members";
import levelbonus from "./modules/levelbonus";
import redeem from "./modules/redeem";
import levelAdjustment from "./modules/levelAdjustment";
import pointBy from "./modules/pointBy";
import basicPoint from "./modules/basicPoint";
import bonusPoint from "./modules/bonusPoint";
import notification from "./modules/notification";
import rewards from "./modules/rewards";
import emailCampaign from "./modules/EmailCampaign";
import campaign from "./modules/campaign";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    customFields,
    references,
    roles,
    users,
    vouchers,
    properties,
    categories,
    pointAdjustment,
    members,
    levelbonus,
    redeem,
    levelAdjustment,
    pointBy,
    basicPoint,
    bonusPoint,
    notification,
    rewards,
    emailCampaign,
    campaign
  }
});
