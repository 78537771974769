import MemberAPI from "../../api/members";
import { Loading } from "element-ui";
import Swal from "sweetalert2";

const state = {
  list: [],
  filter: {
    search: "",
    join_date: "",
    status: ""
  },
  pagination: {
    perPage: 0,
    totalPages: 0,
    currentPage: 0
  },
  detail: {
    fullName: "",
    gender: "",
    birthday: "",
    nationality: "",
    maritial: "",
    idCard: "",
    passport: "",
    totalPoint: 0,
    cardNumber: "",
    level: "",
    nextLevel: "",
    experienceNeededToNextLevel: "",
    retentionExperience: "",
    waitUntil: "",
    memberSince: "",
    enrolmentType: "",
    recommendedBy: "",
    memberReferal: "",
    language: "",
    email: "",
    mobile: "",
    homePhone: "",
    address: "",
    postalCode: "",
    country: "",
    company: "",
    officePhone: "",
    fax: "",
    expirePointThisMonth: 0,
    expirePointNextMonth: 0,
    expirePointNextTwoMonth: 0
  },
  transaction: {
    list: [],
    pagination: {
      perPage: 0,
      totalPages: 0,
      currentPage: 0
    }
  },
  transactionFilter: {
    period_start: "",
    period_end: "",
    point_type: "",
    type: "",
    search: "",
    voucher: "",
    voucher_status: "",
    amount_min: "",
    amount_max: "",
    property_code: ""
  },
  membershipLevel: {
    list: [],
    pagination: {
      perPage: 0,
      totalPages: 0,
      currentPage: 0
    }
  },
  reportingList: [],
  reportingFilter: {
    search: "",
    status: "",
    status_alt: ""
  },
  reportingPagination: {
    perPage: 0,
    totalPages: 0,
    currentPage: 0,
    reportDate: "",
    reportWeek: ""
  },
};

const getFullName = (firstName, lastName) => {
  let output;
  if (firstName != null && lastName != null) {
    output = `${firstName} ${lastName}`;
  } else if (firstName != null) {
    output = firstName;
  } else {
    output = lastName;
  }
  return output;
};

const getters = {
  tableData: state => {
    return state.list.map(item => {
      const fullName = getFullName(item.first_name, item.last_name);
      let status;

      switch (item.status) {
        case 0:
          status = "Deactivated";
          break;
        case 1:
          status = "Active";
          break;
        case 3:
          status = "Activation";
          break;
        case 6:
          status = "Hibernated";
          break;
        case 7:
          status = "Dormant";
          break;
        default:
          status = "Inactive";
          break;
      }

      return {
        fullName: fullName,
        cardNumber: item.card_number,
        email: item.email ? item.email : "",
        phone: item.mobile_phone ? item.mobile_phone : "",
        level: item.level,
        birthday: item.birthday,
        points: item.points,
        memberSince: item.created_at,
        status: status,
      };
    });
  },
  detailData: state => {
    return state.detail;
  },
  tableDataReporting: state => {
    return state.reportingList.map(item => {
      const fullName = getFullName(item.first_name, item.last_name);
      let status;
      let statusAlt;

      switch (item.status) {
        case 1:
          status = "Sync";
          break;
        default:
          status = "Unsync";
          break;
      }

      switch (item.status_alt) {
        case 1:
          statusAlt = "Sync";
          break;
        default:
          statusAlt = "Unsync";
          break;
      }

      return {
        fullName: fullName,
        cardNumber: item.card_number,
        email: item.email ? item.email : "",
        phone: item.mobile_phone ? item.mobile_phone : "",
        rewardsPoints: item.rewards_points,
        corePoints: item.core_points,
        transactionPoints: item.transaction_points,
        status: status,
        statusAlt: statusAlt
      };
    });
  },
};

const actions = {
  getList({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      MemberAPI.getList({
        page: payload.page,
        filter: state.filter,
        action: `Admin: ${rootState.auth.name} view member list`
      })
        .then(response => {
          const data = response.data;
          commit("GET_LIST_SUCCESS", data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getDetail({ commit, rootState }, { cardNumber, name }) {
    let loadingInstance = Loading.service({
      fullscreen: true
    });

    let action;

    if (name === undefined) {
      action = null;
    } else {
      action = `Admin: ${rootState.auth.name} view member with card: ${cardNumber}, name: ${name}`;
    }
    return new Promise((resolve, reject) => {
      MemberAPI.getDetail({
        cardNumber: cardNumber,
        action: action
      })
        .then(response => {
          const data = response.data.data;
          commit("GET_DETAIL_SUCCESS", data);
          loadingInstance.close();
          resolve(response);
        })
        .catch(error => {
          loadingInstance.close();
          reject(error);
        });
    });
  },
  getTransactionHistories({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      MemberAPI.getTransactionHistories({
        cardNumber: payload.cardNumber,
        page: payload.page,
        periodStart: state.transactionFilter.period_start,
        periodEnd: state.transactionFilter.period_end,
        pointType: state.transactionFilter.point_type,
        trxType: state.transactionFilter.type,
        search: state.transactionFilter.search,
        voucher: state.transactionFilter.voucher,
        voucherStatus: state.transactionFilter.voucher_status,
        amountMin: state.transactionFilter.amount_min,
        amountMax: state.transactionFilter.amount_max,
        propertyCode: state.transactionFilter.property_code,
        action: `Admin: ${rootState.auth.name} view member transaction history`
      })
        .then(response => {
          const data = response.data;
          commit("GET_TRANSACTION_HISTORIES_SUCCESS", data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getMemberLevelHistories({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      MemberAPI.getMembershipLevelHistories({
        cardNumber: payload.cardNumber,
        page: payload.page,
        action: `Admin: ${rootState.auth.name} view member level history`
      })
        .then(response => {
          const data = response.data;
          commit("GET_MEMBERSHIP_LEVEL_HISTORIES_SUCCESS", data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  exportTransactionHistoriesToExcel({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      MemberAPI.exportTransactionHistoriesToExcel({
        cardNumber: payload.cardNumber,
        periodStart: state.transactionFilter.period_start,
        periodEnd: state.transactionFilter.period_end,
        pointType: state.transactionFilter.point_type,
        trxType: state.transactionFilter.type,
        search: state.transactionFilter.search,
        voucher: state.transactionFilter.voucher,
        voucherStatus: state.transactionFilter.voucher_status,
        amountMin: state.transactionFilter.amount_min,
        amountMax: state.transactionFilter.amount_max,
        propertyCode: state.transactionFilter.property_code,
        action: `Admin: ${rootState.auth.name} export member transaction history`
      })
        .then(response => {
          const data = response.data;

          if (data.url !== null) {
            window.open(data.url, '_blank');
          } else {
            Swal.fire({
              type: "info",
              title: "Export data is not available!"
            });
          }
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getPointReportingList({ state, commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      MemberAPI.getPointReportingList({
        page: payload.page,
        filter: state.reportingFilter,
        action: `Admin: ${rootState.auth.name} view member point reporting list`
      })
        .then(response => {
          const data = response.data;
          commit("GET_REPORTING_LIST_SUCCESS", data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
};

const mutations = {
  GET_LIST_SUCCESS: (state, payload) => {
    const pagination = payload.meta.pagination;
    state.list = payload.data;
    state.pagination = {
      perPage: pagination.per_page,
      totalPages: pagination.total_pages,
      currentPage: pagination.current_page
    };
  },
  GET_DETAIL_SUCCESS: (state, payload) => {
    const fullName = getFullName(payload.first_name, payload.last_name);
    let points = 0;
    if (
      payload.card.loyalty.wallet != null &&
      payload.card.loyalty.wallet.length > 0
    ) {
      for (let i = 0; i < payload.card.loyalty.wallet.length; i++) {
        points += payload.card.loyalty.wallet[i].balance;
      }
    } else {
      points = 0;
    }

    let expirePointThisMonth = 0;
    let expirePointNextMonth = 0;
    let expirePointNextTwoMonth = 0;

    const expiredSoon = payload.card.loyalty.expiredPointSoon;

    if (expiredSoon != null) {
      if (expiredSoon.thisMonth != null) {
        for (const key in expiredSoon.thisMonth) {
          if (expiredSoon.thisMonth[key].length > 0) {
            expirePointThisMonth += expiredSoon.thisMonth[key]
              .map(item => item.balance)
              .reduce((total, val) => total + val);
          }
        }
      }

      if (expiredSoon.nextMonth != null) {
        for (const key in expiredSoon.nextMonth) {
          if (expiredSoon.nextMonth[key].length > 0) {
            expirePointNextMonth += expiredSoon.nextMonth[key]
              .map(item => item.balance)
              .reduce((total, val) => total + val);
          }
        }
      }

      if (expiredSoon.nextTwoMonth != null) {
        for (const key in expiredSoon.nextTwoMonth) {
          if (expiredSoon.nextTwoMonth[key].length > 0) {
            expirePointNextTwoMonth += expiredSoon.nextTwoMonth[key]
              .map(item => item.balance)
              .reduce((total, val) => total + val);
          }
        }
      }
    }

    const currentLevel = payload.card.loyalty.level.name;
    const levels = payload.card.loyalty.levels;
    const retentionExperience = levels.find(item => item.name === currentLevel)
      ?.retentionExperience;

    state.detail = {
      fullName: fullName,
      gender: payload.gender,
      birthday: payload.birthday,
      nationality: payload.nationality,
      maritial: payload.maritial_status,
      idCard: payload.id_card,
      passport: payload.passport_no,
      cardNumber: payload.card_number,
      level: payload.card.loyalty.level.name,
      nextLevel:
        payload.card.loyalty.nextLevel != null
          ? payload.card.loyalty.nextLevel.name
          : "",
      experienceNeededToNextLevel:
        payload.card.loyalty.experienceNeededToNextLevel,
      retentionExperience: retentionExperience ?? "",
      waitUntil: payload.card.loyalty.waitUntil ?? "",
      memberSince: payload.created_at,
      enrolmentType: payload.enrollment_type,
      recommendedBy: payload.property_code,
      memberReferal: payload.member_referral,
      language: payload.language,
      email: payload.email,
      mobile: payload.mobile_phone,
      homePhone: payload.home_phone,
      address: payload.address,
      postalCode: payload.postal_code,
      country: payload.country_of_residence,
      company: payload.company,
      officePhone: payload.office_phone,
      fax: payload.fax_number,
      totalPoint: points,
      status: payload.status,
      expirePointThisMonth: expirePointThisMonth,
      expirePointNextMonth: expirePointNextMonth,
      expirePointNextTwoMonth: expirePointNextTwoMonth
    };
  },
  SET_SEARCH_KEYWORD: (state, keyword) => {
    state.filter.search = keyword;
  },
  CLEAR_FILTER: state => {
    state.filter.search = "";
    state.filter.join_date = "";
    state.filter.status = "";
  },
  GET_TRANSACTION_HISTORIES_SUCCESS: (state, payload) => {
    const pagination = payload.meta.pagination;
    state.transaction.list = payload.data;
    state.transaction.pagination = {
      perPage: pagination.per_page,
      totalPages: pagination.total_pages,
      currentPage: pagination.current_page
    };
  },
  GET_MEMBERSHIP_LEVEL_HISTORIES_SUCCESS: (state, payload) => {
    const pagination = payload.meta.pagination;
    state.membershipLevel.list = payload.data;
    state.membershipLevel.pagination = {
      perPage: pagination.per_page,
      totalPages: pagination.total_pages,
      currentPage: pagination.current_page
    };
  },
  CLEAR_TRANSACTION_FILTER: state => {
    state.transactionFilter.period_start = "";
    state.transactionFilter.period_end = "";
    state.transactionFilter.point_type = "";
    state.transactionFilter.type = "";
    state.transactionFilter.search = "";
    state.transactionFilter.voucher = "";
    state.transactionFilter.voucher_status = "";
    state.transactionFilter.amount_min = "";
    state.transactionFilter.amount_max = "";
    state.transactionFilter.property_code = "";
  },
  GET_REPORTING_LIST_SUCCESS: (state, payload) => {
    const pagination = payload.meta.pagination;
    state.reportingList = payload.data;
    state.reportingPagination = {
      perPage: pagination.per_page,
      totalPages: pagination.total_pages,
      currentPage: pagination.current_page,
      reportDate: payload.data.length > 0 ? payload.data[0].created_at : "",
      reportWeek: payload.data.length > 0 ? payload.data[0].week_number : ""
    };
  },
  SET_REPORTING_SEARCH_KEYWORD: (state, keyword) => {
    state.reportingFilter.search = keyword;
  },
  CLEAR_REPORTING_FILTER: state => {
    state.reportingFilter.search = "";
    state.reportingFilter.status = "";
    state.reportingFilter.status_alt = "";
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
