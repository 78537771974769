import DashboardLayout from "src/pages/Layout/DashboardLayout.vue";
import AuthLayout from "src/pages/Layout/AuthLayout.vue";
// GeneralViews
import NotFound from "src/pages/GeneralViews/NotFoundPage.vue";

// Page Headers
const DashboardHeader = () =>
  import(
    /* webpackChunkName: "dashboard" */ "src/pages/Dashboard/Dashboard/DashboardHeader.vue"
  );
import DefaultHeader from "src/pages/Home/DefaultHeader";
import store from "../store";

// Dashboard pages
const Dashboard = () =>
  import(
    /* webpackChunkName: "dashboard" */ "src/pages/Dashboard/Dashboard/Dashboard.vue"
  );

// Auth
const Login = () =>
  import(/* webpackChunkName: "auth" */ "src/pages/Auth/Login.vue");

// Members
const MemberList = () =>
  import(/* webpackChunkName: "members" */ "src/pages/Members/MemberList.vue");
const MemberDetail = () =>
  import(
    /* webpackChunkName: "memberdetails" */ "src/pages/Members/MemberDetail.vue"
  );
const PointReportingList = () =>
  import(
    /* webpackChunkName: "pointreportinglist" */ "src/pages/Members/PointReportingList.vue"
  );

const RewardsCatalogue = () =>
  import(
    /* webpackChunkName: "rewardscatalogue" */ "src/pages/Rewards/RewardsList.vue"
  );
const RewardsDetail = () =>
  import(
    /* webpackChunkName: "rewardsdetail" */ "src/pages/Rewards/RewardsDetail.vue"
  );
// Custom Field List
const CustomFieldList = () =>
  import(
    /* webpackChunkName: "customfields" */ "src/pages/CustomFields/CustomFieldList.vue"
  );

const CreateCustomField = () =>
  import(
    /* webpackChunkName: "createcustomfield" */ "src/pages/CustomFields/CreateCustomField.vue"
  );

const UpdateCustomField = () =>
  import(
    /* webpackChunkName: "updatecustomfield" */ "src/pages/CustomFields/UpdateCustomField.vue"
  );

// Roles
const RoleList = () =>
  import(/* webpackChunkName: "rolelist" */ "src/pages/Roles/RoleList.vue");

const CreateRole = () =>
  import(/* webpackChunkName: "createrole" */ "src/pages/Roles/CreateRole.vue");

const UpdateRole = () =>
  import(/* webpackChunkName: "updaterole" */ "src/pages/Roles/UpdateRole.vue");

// Voucher
const VoucherList = () =>
  import(
    /* webpackChunkName: "voucherlist" */ "src/pages/Vouchers/VoucherList.vue"
  );
const CreateVoucher = () =>
  import(
    /* webpackChunkName: "createvoucher" */ "src/pages/Vouchers/CreateVoucher.vue"
  );
const UpdateVoucher = () =>
  import(
    /* webpackChunkName: "updatevoucher" */ "src/pages/Vouchers/UpdateVoucher.vue"
  );
const RedeemList = () =>
  import(
    /* webpackChunkName: "redeemlist" */ "src/pages/Vouchers/RedeemList.vue"
  );
const RedeemDetail = () =>
  import(
    /* webpackChunkName: "redeemlist" */ "src/pages/Vouchers/RedeemDetail.vue"
  );

// Manual Adjustment
const PointAdjustmentList = () =>
  import(
    /* webpackChunkName: "manualadjustmentlist" */ "src/pages/ManualAdjustment/PointAdjustmentList.vue"
  );

const PointAdjustmentForm = () =>
  import(
    /* webpackChunkName: "manualadjustmentlist" */ "src/pages/ManualAdjustment/PointAdjustmentForm.vue"
  );

const LevelAdjustmentList = () =>
  import(
    /* webpackChunkName: "leveladjustment" */ "src/pages/ManualAdjustment/LevelAdjustmentList.vue"
  );

const LevelAdjustmentCreate = () =>
  import(
    /* webpackChunkName: "leveladjustmentcreate" */ "src/pages/ManualAdjustment/LevelAdjustmentCreate.vue"
  );

const LevelAdjustmentUpdate = () =>
  import(
    /* webpackChunkName: "leveladjustmentupdate" */ "src/pages/ManualAdjustment/LevelAdjustmentUpdate.vue"
  );

// Category
const CategoryList = () =>
  import(
    /* webpackChunkName: "categoryList" */ "src/pages/Category/CategoryList.vue"
  );

const CategoryCreate = () =>
  import(
    /* webpackChunkName: "categoryCreate" */ "src/pages/Category/CategoryCreate.vue"
  );

const CategoryUpdate = () =>
  import(
    /* webpackChunkName: "categoryUpdate" */ "src/pages/Category/CategoryUpdate.vue"
  );

const CategoryVoucherList = () =>
  import(
    /* webpackChunkName: "categoryVoucherList" */ "@/pages/Category/CategoryVoucherList.vue"
    );

// Point Earning Rules
const PointEarningRuleList = () =>
  import(
    /* webpackChunkName: "pointEarningRuleList" */ "src/pages/PointEarningRules/PointEarningRuleList.vue"
  );
const CreateBasicPointSettings = () =>
  import(
    /* webpackChunkName: "createBasicPointSettings" */ "src/pages/PointEarningRules/BasicPointSettings/CreateBasicPointSettings.vue"
  );
const UpdateBasicPointSettings = () =>
  import(
    /* webpackChunkName: "updateBasicPointSettings" */ "src/pages/PointEarningRules/BasicPointSettings/UpdateBasicPointSettings.vue"
  );
const CreateBonusPointSettings = () =>
  import(
    /* webpackChunkName: "createBonusPointSettings" */ "src/pages/PointEarningRules/BonusPointSettings/CreateBonusPointSettings.vue"
  );
const UpdateBonusPointSettings = () =>
  import(
    /* webpackChunkName: "updateBonusPointSettings" */ "src/pages/PointEarningRules/BonusPointSettings/UpdateBonusPointSettings.vue"
  );

// Campaign
const EmailCampaignList = () =>
  import(
    /* webpackChunkName: "emailCampaignList" */ "src/pages/EmailCampaign/EmailCampaignList.vue"
  );
const EmailCampaignDetail = () =>
  import(
    /* webpackChunkName: "emailCampaignDetail" */ "src/pages/EmailCampaign/EmailCampaignDetail.vue"
  );
const CreateEmailCampaign = () =>
  import(
    /* webpackChunkName: "emailCampaignList" */ "src/pages/EmailCampaign/CreateEmailCampaign.vue"
  );
const EditEmailCampaign = () =>
  import(
    /* webpackChunkName: "emailCampaignList" */ "src/pages/EmailCampaign/EditEmailCampaign.vue"
  );

// Settings
const EmailSettings = () =>
  import(
    /* webpackChunkName: "emailSettings" */ "src/pages/Settings/EmailSettings/EmailSettings.vue"
  );
const EmailTemplateDetail = () =>
  import(
    /* webpackChunkName: "emailTemplateDetail" */ "src/pages/Settings/EmailSettings/EmailTemplateDetail.vue"
  );

const EmailTemplateUpload = () =>
  import(
    /* webpackChunkName: "emailTemplateUpload" */ "src/pages/Settings/EmailSettings/EmailTemplateUpload.vue"
  );

const PointByRevenueList = () =>
  import(
    /* webpackChunkName: "emailTemplateUpload" */ "src/pages/PointByRevenue/PointByRevenueList"
  );
const CreatePointByRevenue = () =>
  import(
    /* webpackChunkName: "emailTemplateUpload" */ "src/pages/PointByRevenue/CreatePointByRevenue"
  );
const UpdatePointByRevenue = () =>
  import(
    /* webpackChunkName: "emailTemplateUpload" */ "src/pages/PointByRevenue/UpdatePointByRevenue"
  );

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters["auth/isAuthenticated"]) {
    next();
    return;
  }
  next({ name: "Home" });
};

const ifAuthenticated = (to, from, next) => {
  if (store.getters["auth/isAuthenticated"]) {
    next();
    return;
  }
  next({ name: "Login" });
};

// Menu
let membersMenu = {
  path: "/members",
  component: DashboardLayout,
  redirect: "/members/list",
  name: "Members",
  children: [
    {
      path: "list",
      name: "Members",
      components: { default: MemberList, header: DefaultHeader },
      beforeEnter: ifAuthenticated
    },
    {
      path: "detail/:cardNumber/:slug",
      name: "Member Details",
      components: { default: MemberDetail, header: DefaultHeader },
      beforeEnter: ifAuthenticated
    },
    {
      path: "point-reporting",
      name: "Point Reporting",
      components: { default: PointReportingList, header: DefaultHeader },
      beforeEnter: ifAuthenticated
    }
  ]
};

let pointEarningRulesMenu = {
  path: "/point-earning-rules",
  component: DashboardLayout,
  redirect: "/point-earning-rules/list",
  name: "PointEarningRules",
  children: [
    {
      path: "create-basic-point-settings",
      name: "Create Basic Point Settings",
      components: { default: CreateBasicPointSettings, header: DefaultHeader },
      beforeEnter: ifAuthenticated
    },
    {
      path: "update-basic-point-settings/:id/:slug",
      name: "Update Basic Point Settings",
      components: { default: UpdateBasicPointSettings, header: DefaultHeader },
      beforeEnter: ifAuthenticated
    },
    {
      path: "create-bonus-point-settings",
      name: "Create Bonus Point Settings",
      components: { default: CreateBonusPointSettings, header: DefaultHeader },
      beforeEnter: ifAuthenticated
    },
    {
      path: "update-bonus-point-settings/:id/:slug",
      name: "Update Bonus Point Settings",
      components: { default: UpdateBonusPointSettings, header: DefaultHeader },
      beforeEnter: ifAuthenticated
    },
    {
      path: "list",
      name: "Point Earning Rules",
      components: { default: PointEarningRuleList, header: DefaultHeader },
      beforeEnter: ifAuthenticated
    }
  ]
};

let customFieldsMenu = {
  path: "/custom-fields",
  component: DashboardLayout,
  redirect: "/custom-fields/list",
  name: "CustomFields",
  children: [
    {
      path: "create-fields",
      name: "Create Fields",
      components: { default: CreateCustomField, header: DefaultHeader },
      beforeEnter: ifAuthenticated
    },
    {
      path: "update-custom-field/:code/:slug",
      name: "Update Custom Field",
      components: { default: UpdateCustomField, header: DefaultHeader },
      beforeEnter: ifAuthenticated
    },
    {
      path: "list",
      name: "Custom Fields",
      components: { default: CustomFieldList, header: DefaultHeader },
      beforeEnter: ifAuthenticated
    }
  ]
};

let roleMenu = {
  path: "/roles",
  component: DashboardLayout,
  redirect: "/roles/list",
  name: "Roles",
  children: [
    {
      path: "create-role",
      name: "Create Role",
      components: { default: CreateRole, header: DefaultHeader },
      beforeEnter: ifAuthenticated
    },
    {
      path: "edit-role/:id/:slug",
      name: "Edit Role",
      components: { default: UpdateRole, header: DefaultHeader },
      beforeEnter: ifAuthenticated
    },
    {
      path: "list",
      name: "Role List",
      components: { default: RoleList, header: DefaultHeader },
      beforeEnter: ifAuthenticated
    }
  ]
};

let settingsMenu = {
  path: "/settings",
  component: DashboardLayout,
  redirect: "/settings/email",
  name: "Settings",
  children: [
    {
      path: "email",
      name: "Email Settings",
      components: { default: EmailSettings, header: DefaultHeader },
      beforeEnter: ifAuthenticated
    },
    {
      path: "email-template-detail/:code/:slug",
      name: "Email Template Detail",
      components: { default: EmailTemplateDetail, header: DefaultHeader },
      beforeEnter: ifAuthenticated
    },
    {
      path: "email-template-upload/:code",
      name: "Email Template Upload",
      components: { default: EmailTemplateUpload, header: DefaultHeader },
      beforeEnter: ifAuthenticated
    }
  ]
};

let voucherMenu = {
  path: "/vouchers",
  component: DashboardLayout,
  redirect: "/vouchers/list",
  name: "Vouchers",
  children: [
    {
      path: "list",
      name: "Voucher List",
      components: { default: VoucherList, header: DefaultHeader },
      beforeEnter: ifAuthenticated
    },
    {
      path: "create-voucher",
      name: "Create Voucher",
      components: { default: CreateVoucher, header: DefaultHeader },
      beforeEnter: ifAuthenticated
    },
    {
      path: "edit-voucher/:id/:slug",
      name: "Edit Voucher",
      components: { default: UpdateVoucher, header: DefaultHeader },
      beforeEnter: ifAuthenticated
    },
    {
      path: "redeem",
      name: "E-Voucher Management",
      components: { default: RedeemList, header: DefaultHeader },
      beforeEnter: ifAuthenticated
    },
    {
      path: "redeem/:id/:slug",
      name: "E-Voucher Management Details",
      components: { default: RedeemDetail, header: DefaultHeader },
      beforeEnter: ifAuthenticated
    }
  ]
};

let manualAdjustmentMenu = {
  name: "ManualAdjustment",
  path: "/manual-adjustment",
  component: DashboardLayout,
  redirect: "/manual-adjustment/point",
  children: [
    {
      path: "point",
      name: "Point Adjustment List",
      components: { default: PointAdjustmentList, header: DefaultHeader },
      beforeEnter: ifAuthenticated
    },
    {
      path: "point/form/new",
      name: "Manual Adjustment - New Point Adjustment Form",
      components: { default: PointAdjustmentForm, header: DefaultHeader },
      beforeEnter: ifAuthenticated
    },
    {
      path: "point/form/:id",
      name: "Manual Adjustment - Point Adjustment Form",
      components: { default: PointAdjustmentForm, header: DefaultHeader },
      beforeEnter: ifAuthenticated
    },
    {
      path: "point/form/:id/approve",
      name: "Manual Adjustment - Approve Point Adjustment Form",
      components: { default: PointAdjustmentForm, header: DefaultHeader },
      beforeEnter: ifAuthenticated
    },
    {
      path: "level",
      name: "Level Adjustment List",
      components: { default: LevelAdjustmentList, header: DefaultHeader },
      beforeEnter: ifAuthenticated
    },
    {
      path: "level/create",
      name: "Level Adjustment Create",
      components: { default: LevelAdjustmentCreate, header: DefaultHeader },
      beforeEnter: ifAuthenticated
    },
    {
      path: "level/edit/:id",
      name: "Level Adjustment Edit",
      components: { default: LevelAdjustmentUpdate, header: DefaultHeader },
      beforeEnter: ifAuthenticated
    }
  ]
};

let categoryMenu = {
  name: "Category",
  path: "/categories",
  component: DashboardLayout,
  children: [
    {
      path: "/categories",
      name: "Category List",
      components: { default: CategoryList, header: DefaultHeader },
      beforeEnter: ifAuthenticated
    },
    {
      path: "create",
      name: "Create Category",
      components: { default: CategoryCreate, header: DefaultHeader },
      beforeEnter: ifAuthenticated
    },
    {
      path: "edit/:category_id/:slug",
      name: "Edit Category",
      components: { default: CategoryUpdate, header: DefaultHeader },
      beforeEnter: ifAuthenticated
    },
    {
      path: "/category/vouchers/:id/:slug",
      name: "Category - Voucher List",
      components: { default: CategoryVoucherList, header: DefaultHeader },
      beforeEnter: ifAuthenticated
    }
  ]
};

let campaignMenu = {
  path: "/email-campaign",
  component: DashboardLayout,
  redirect: "/email-campaign/list",
  name: "Email Campaign",
  children: [
    {
      path: "list",
      name: "Email Campaign List",
      components: { default: EmailCampaignList, header: DefaultHeader },
      beforeEnter: ifAuthenticated
    },
    {
      path: "detail/:id",
      name: "Email Campaign Detail",
      components: { default: EmailCampaignDetail, header: DefaultHeader },
      beforeEnter: ifAuthenticated
    },
    {
      path: "create",
      name: "Campaign Email - Create Email",
      components: { default: CreateEmailCampaign, header: DefaultHeader },
      beforeEnter: ifAuthenticated
    },
    {
      path: "edit/:id/:slug",
      name: "Campaign Email - Edit Email",
      components: { default: EditEmailCampaign, header: DefaultHeader },
      beforeEnter: ifAuthenticated
    }
  ]
};

let authPages = {
  path: "/",
  component: AuthLayout,
  name: "Authentication",
  children: [
    {
      path: "/login",
      name: "Login",
      component: Login,
      beforeEnter: ifNotAuthenticated
    }
  ]
};

// Rewards
let rewardsMenu = {
  path: "/rewards",
  component: DashboardLayout,
  redirect: "/rewards/list",
  name: "Rewards",
  children: [
    {
      path: "list",
      name: "Rewards Catalogue",
      components: { default: RewardsCatalogue, header: DefaultHeader },
      beforeEnter: ifAuthenticated
    },
    {
      path: "list/:id/:slug",
      name: "Rewards Detail",
      components: { default: RewardsDetail, header: DefaultHeader },
      beforeEnter: ifAuthenticated
    }
  ]
};

// Point By Revenue
let pointByRevenueMenu = {
  path: "/point-by-revenue",
  component: DashboardLayout,
  redirect: "/point-by-revenue/list",
  name: "PointByRevenue",
  children: [
    {
      path: "list",
      name: "Point Configuration - Point By Revenue",
      components: { default: PointByRevenueList, header: DefaultHeader },
      beforeEnter: ifAuthenticated
    },
    {
      path: "create",
      name: "Create Point By Revenue",
      components: { default: CreatePointByRevenue, header: DefaultHeader },
      beforeEnter: ifAuthenticated
    },
    {
      path: "update/:code/:slug",
      name: "Update Point By Revenue",
      components: { default: UpdatePointByRevenue, header: DefaultHeader },
      beforeEnter: ifAuthenticated
    }
  ]
};
const routes = [
  {
    path: "/",
    redirect: "/dashboard",
    name: "Home"
  },
  membersMenu,
  pointEarningRulesMenu,
  customFieldsMenu,
  roleMenu,
  voucherMenu,
  manualAdjustmentMenu,
  categoryMenu,
  authPages,
  rewardsMenu,
  settingsMenu,
  campaignMenu,
  pointByRevenueMenu,
  {
    path: "/",
    component: DashboardLayout,
    redirect: "/dashboard",
    name: "Dashboard",
    children: [
      {
        path: "dashboard",
        name: "Dashboard",
        components: { default: Dashboard, header: DashboardHeader },
        beforeEnter: ifAuthenticated
      }
    ]
  },
  { path: "*", component: NotFound }
];

export default routes;
