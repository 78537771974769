import PointByAPI from "../../api/pointBy";

const state = {
  pointByAll: [],
  pointByAllNonBasic: [],
  pointBy: {
    code: null,
    label: "",
    childs: []
  }
};

const getters = {};

const actions = {
  getPointByAll({ commit }, payload) {
    return new Promise((resolve, reject) => {
      PointByAPI.getAll({
        onlyBasic: payload.onlyBasic
      })
        .then(response => {
          commit("GET_ALL_SUCCESS", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getPointByAllNonBasic({ commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      PointByAPI.getAllNonBasic({
        nonBasic: payload.nonBasic,
        action: `Admin: ${rootState.auth.name} view point by revenue`
      })
        .then(response => {
          commit("GET_ALL_NON_BASIC_SUCCESS", response.data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  getPointBy({ commit, rootState }, payload) {
    return new Promise((resolve, reject) => {

      PointByAPI.getDetail({
        code: payload.code,
        action: `Admin: ${rootState.auth.name} view ${payload.module}`
      })
        .then(response => {
          const data =
            {
              ...response.data.data,
              childs: response.data.data.childs.map(v => v.child.code)
            } || {};
          commit("GET_POINT_BY_SUCCESS", data);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  savePointBy({ state, rootState }) {
    if (state.pointBy.code !== null) {
      return new Promise((resolve, reject) => {
        PointByAPI.putPointBy({
          data: state.pointBy,
          action: `Admin: ${rootState.auth.name} update point by with id: ${state.pointBy.code}, name: ${state.pointBy.label}`
        })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    } else {
      return new Promise((resolve, reject) => {
        PointByAPI.postPointBy({
          data: state.pointBy,
          action: `Admin: ${rootState.auth.name} create point by with name: ${state.pointBy.label}`
        })
          .then(response => {
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    }
  },
  deletePointBy({ commit, rootState }, payload) {
    return new Promise((resolve, reject) => {
      PointByAPI.deletePointBy({
        code: payload.code,
        action: `Admin: ${rootState.auth.name} delete point by with id: ${payload.code}, name: ${payload.label}`
      })
        .then(response => {
          commit("DELETE_POINT_BY_SUCCESS", payload.code);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  }
};

const mutations = {
  GET_ALL_SUCCESS: (state, payload) => {
    state.pointByAll = payload.data;
  },
  GET_ALL_NON_BASIC_SUCCESS: (state, payload) => {
    state.pointByAllNonBasic = payload.data;
  },
  GET_POINT_BY_SUCCESS: (state, payload) => {
    state.pointBy = payload;
  },
  DELETE_POINT_BY_SUCCESS: (state, code) => {
    state.pointByAllNonBasic = state.pointByAllNonBasic.filter(
      item => item.code !== code
    );
  },
  RESET_FORM: state => {
    state.pointBy = {
      code: null,
      label: "",
      childs: []
    };
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
